import React from 'react';
import { Link } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/SEO';

const ThankYou = () => {
  return (
    <div>
      <SEO title="Thank you" />

      <Header />

      <main
        className="
          bg-dark-white pt-10 sm:pt-16 md:pt-24 lg:pt-28 xl:pt-32
          pb-14 sm:pb-20 md:pb-28 lg:pb-32 xl:pb-36
        "
      >
        <div className="container">
          <div className="md:grid md:grid-cols-12 gap-8">
            <div
              className="
                md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3
                xl:col-span-6 xl:col-start-4
              "
            >
              <div className="bg-lighter-green rounded-md mt-12">
                <div className="xs:px-9 px-6 py-7 relative">
                  <div className="absolute -top-8 left-1/2">
                    <StaticImage
                      src="../images/ivaylo-mladenov.jpg"
                      width={72}
                      alt="Ivaylo Mladenov, Webcast consultant"
                      className="rounded-full relative -left-1/2"
                    />
                  </div>
                  <h3 className="text-blue text-xl font-bold text-center mb-6 mt-8">
                    Thank you for reaching out!
                  </h3>
                  <div className="text-lg text-blue text-center">
                    <p>
                      We will be in touch shortly. In the mean time if you have any questions you
                      can always give as a call:
                    </p>
                    <p>
                      <strong>+32 2 881 01 15</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center pt-12">
            <Link
              to="/get-a-quote"
              className="
                bg-primary text-white text-xl font-bold py-4 px-8
                border border-primary rounded-sm focus-ring transition hover:bg-dark-primary
              "
            >
              Back to the form
            </Link>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ThankYou;
